import React, { useState, useEffect } from 'react';

import './css/App.css';
import { translate } from "./language.js";

function App() {    
    const [applications] = useState([
        {
            name: "Kid Story",
            description: `
            Kid Story turns reading into an journey fueled by your child's passions! Whether they love dinosaurs, space, or superheroes, we can craft stories based on their interests and skill level. Start with short, audio-backed tales, then level up to nofiction, sci-fi, —all personalized to keep them hooked. Plus, let them become authors with the Story Creator: design characters, worlds, and plots inspired by their favorite topics. No boredom, just boundless curiosity! And curiosity is the best way to learn and grow their reading skills.`,
            image: "https://storage.googleapis.com/darkforce-public/web/story_cover/age_12.jpg",
            url: "https://metabook.ai/kidstory",
            links: [
                {
                    name: "Enter",
                    image: "https://storage.googleapis.com/darkforce-public/web/story_cover/age_12.jpg",
                    url: "https://metabook.ai/inanotherlife"
                }
            ]
        },
        {
            name: "In Another life",
            description: `Create your story across different worlds and eras. Explore, fight, love, and discover your fate. Join now and shape your immersive, narrative-driven adventure!`,
            image: "./image/icon_inanotherlife.jpg",
            url: "https://metabook.ai/inanotherlife",
            links: [
                {
                    name: "iOS",
                    image: "./image/ios.jpg",
                    url: "https://apps.apple.com/app/in-another-life/id6711340910"
                },
                {
                    name: "Android",
                    image: "./image/android.jpg",
                    url: "https://storage.googleapis.com/darkforce-public/android_release/inanotherlife_1.apk"
                },
            ]
        },
        {
            name: "Read Player Stories",
            description: "Just want to read stories? No problem. Dive into the adventure tales from players across the game. Experience their struggles, battles, romances, disappointments, victories, and so much more. Get ready to be captivated!",
            image: "./image/icon_readbook.jpg",
            url: "https://metabook.ai/stories"
        },
        {
            name: "Darkforce",
            description: "Unleassh your fantasy by using AI tools for images. Swap faces in any photo/video. Anything you want to play on a photo.",
            image: "./image/icon_darkforce.jpg",
            url: "https://darkforce.ai"
        }

    ])
    return (
        <div className="App">
            <header className="App-header" style={{marginBottom: '5px'}}>
                <h1 style={{marginLeft: '0px'}} onClick={() => {
                    window.location.href = "/"
                }}>{translate("Metabook.AI")}
            <div style={{color: 'white', fontSize: '14px', textAlign: 'left'}}>
                Explore the Fun of AI
            </div>

                </h1>
                
                {/* <div style={{display: 'flex', justifyContent: 'center'}}>
                    <button className="language-button" onClick={() => translate('en')}>English</button>
                    <button className="language-button" onClick={() => translate('zh')}>中文</button>
                </div> */}
            </header>

            <div className="app-grid">
                {applications.map((app, index) => (
                    <div className="app-item" key={index} style={{
                        display: 'flex',
                        flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                        alignItems: 'flex-start',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        cursor: 'pointer',
                    }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'translateY(-5px)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'translateY(0)';
                            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                        }}
                    >
                        <div style={{width: '25vw', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}
                        onClick={() => {
                            window.location.href = app.url
                        }}>
                            <img src={app.image} alt={app.name} style={{paddingTop: '12px', width: '25vw', height: 'auto', borderRadius: '4px' }}/>
                        </div>
                        <div style={{ padding: '10px', paddingTop: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <div style={{ fontSize: '1.3em', fontWeight: 'bold', color: '#333', marginTop: '0px', textAlign: index % 2 === 0 ? 'left' : 'right' }}
                            onClick={() => {
                                window.location.href = app.url
                            }}
                            on>{app.name}</div>
                            <div style={{ color: '#666', lineHeight: '1.5', fontSize: '1.0em', paddingTop: '5px', paddingBottom: '5px', marginTop: '0px', textAlign: 'left', paddingRight: '5px' }} 
                            onClick={() => {
                                window.location.href = app.url
                            }}
                            >{app.description}</div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                            {
                                app.links && app.links.map((link, index) => (
                                    <div key={index} style={{alignItems: 'left', paddingRight: '10px', paddingBottom: '0px', textAlign: 'left'}} onClick={() => {
                                        window.location.href = link.url
                                    }}>
                                        <img src={link.image} alt={link.name} style={{ height: '40px', width: 'auto', marginRight: '5px' }}/>
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    </div>
                ))}
            </div>


            <div className='copyright'>
                © 2024 Metabook.AI · All rights reserved.<br />
                Contact: <span className='link-text-white'>admin@metabook.ai</span>
            </div>
        </div>
    );
}
export default App;
